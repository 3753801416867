import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';

const Nagelpilzerkrankungen = ({ data, location, ...props }) => {
    return (
        <Layout
            location={location}
            title="Nagelpilzerkrankungen"
            noSlider
            {...props}
        >
            <p>Ein gesunder Nagel widersteht einem Angriff von Pilzsporen.</p>
            <p>
                Doch können Begleiterkrankungen, Medikamente, drückendes
                Schuhwerk, Nagelverletzungen beim Tanzen oder Sport dazu führen,
                dass die eigentlich feste Nagelstruktur aufgebrochen wird und
                sich Pilzsporen einnisten können.
            </p>
            <p>
                Dabei ist nicht jede sichtbare Nagelveränderung gleichzusetzen
                mit einem Nagelpilz.{' '}
            </p>
            <p>
                Nur ca. 30% aller Nagelveränderungen sind durch Pilze
                verursacht. Das bedeutet, dass in ca. 70% der Nagelveränderungen
                andere Ursachen verantwortlich sind. Leider sind diese sehr
                selten als Einzelursache identifizierbar und zielgerichtet
                behandelbar.
            </p>
            <p>
                Wir versuchen immer die Ursache für Ihre Nagelveränderungen zu
                erkennen. Das Anlegen einer Pilzkultur oder eine histologische
                Untersuchung einer Nagelprobe liefern wichtige Erkenntnisse.
            </p>
            <p>
                Wurde ein Pilz nachgewiesen, beginnt eine ca. 1-2 Jahre dauernde
                Therapie, die umso schneller hilft, je schneller der Nagel noch
                wächst. Dies hängt auch vom Lebensalter ab.
            </p>
            <p>
                Abhängig vom Ausmass des Nagelbefalles erfolgt die Therapie mit
                Tabletten, Nagellack, Nagellaser, als alleinige Therapie oder in
                Kombination.{' '}
            </p>

            <Questions title="Nagelpilzerkrankungen" />
        </Layout>
    );
};

export default Nagelpilzerkrankungen;
